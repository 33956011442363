import { applyMiddleware, compose, createStore } from "redux";

import logger from "redux-logger";
import { persistStore } from "redux-persist";
import rootReducer from "./root-reducer";
import thunk from "redux-thunk";

const middleware = [thunk];
if (process.env.NODE_ENV === "development") {
    middleware.push(logger);
}
// declare global {
//     interface Window {
//         __REDUX_DEVTOOLS_EXTENSION_COMPOSE__?: typeof compose;
//     }
// }

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

export const store = createStore(
    rootReducer,
    composeEnhancers(applyMiddleware(...middleware))
);
// export const store = createStore(rootReducer, applyMiddleware(...middleware));

export const persistor = persistStore(store);
